<template>
  <div>
    <div
      class="modal fade"
      id="modal-escaner-documento"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Replicas</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
              @click="clearDraftDocuments()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="card-body">
            <!-- datos del viaje  -->
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Informacion General
                    <button
                      @click="toggleCollapse"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed ? "+" : "-" }}
                    </button>
                  </legend>

                  <div v-show="!isCollapsed">
                    <div v-if="diferencia_guia_notificacione_cobro_id">
                      <div class="card-body text-muted text-s">
                        <div class="row justify-content-center">
                          <div class="col mb-3">
                            <ul class="fa-ul mb-0">
                              <li>
                                <div class="row">
                                  <div class="col-md-4">ID:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{
                                      diferencia_guia_notificacione_cobro_id
                                    }}</strong>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-md-4">Guia:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{
                                      datos.viaje
                                        ? datos.viaje.numero_guia
                                        : " "
                                    }}</strong>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-md-4">Numero de Viaje:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{ datos.viaje_id }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">Ruta:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{
                                      datos.viaje.ruta.nombre
                                    }}</strong>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div class="col mb-5">
                            <ul class="fa-ul mb-0">
                              <li>
                                <div class="row">
                                  <div class="col-md-4">Vehiculo:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{
                                      datos.viaje
                                        ? datos.viaje.vehiculo.placa
                                        : ""
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">Remolque:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{
                                      datos.viaje
                                        ? datos.viaje.remolque.placa
                                        : ""
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">Transportadora:</div>
                                  <div class="col-md-8 mb-1">
                                    <strong>{{
                                      datos.viaje
                                        ? datos.viaje.transportadora
                                            .razon_social
                                        : ""
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">Conductor:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong
                                      >{{
                                        datos.viaje
                                          ? datos.viaje.conductor.nombres
                                          : ""
                                      }}
                                      {{
                                        datos.viaje
                                          ? datos.viaje.conductor.apellidos
                                          : ""
                                      }}</strong
                                    >
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div class="col mb-3">
                            <ul class="fa-ul mb-0">
                              <li>
                                <div class="row">
                                  <div class="col-md-4">NSV Cargue:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{ datos.nsv_cargue }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">NSV Descargue:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{ datos.nsv_descargue }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">NSV diferencia:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{ datos.nsv_diferencia }}</strong>
                                  </div>
                                  <div
                                    class="row text-center"
                                    v-show="
                                      datos.tarifa_transporte &&
                                        $store.getters.can(
                                          'hidrocarburos.faltantes.confirmador'
                                        )
                                    "
                                  >
                                    <div class="col-md-4">
                                      Tarifa Transporte:
                                    </div>
                                    <div class="col-md-6 mb-1">
                                      <strong>
                                        ${{
                                          Number(
                                            datos.tarifa_transporte
                                          ).toLocaleString("es-CO", {
                                            minimumFractionDigits: 2,
                                          })
                                        }}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <!--                             <div
                              class="col-md-3 text-center"
                              v-if="datos.cantidad_envios >= 1"
                            >
                              <strong>Numero de Replica </strong>
                              <div class="text-center"></div>
                              <div
                                class="text-center"
                                v-if="datos.cantidad_envios >= 1"
                              >
                                {{ datos.cantidad_envios }}
                              </div>
                            </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <div class="row">
              <!-- Cargue de Documentos-->
              <div
                class="col-md-6 col-sm-12 col-xs-12"
                v-if="
                  (datos.cantidad_envios <= 1 &&
                    [2, 6].includes(datos.estado) &&
                    $store.getters.can(
                      'hidrocarburos.faltantes.subirDocuemtos'
                    )) ||
                    (datos.intento == 1 &&
                      datos.cantidad_envios <= 2 &&
                      [2, 6].includes(datos.estado) &&
                      $store.getters.can(
                        'hidrocarburos.faltantes.subirDocuemtos'
                      ))
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Cargue de Documentos
                    <button
                      @click="toggleCollapse2"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed2 ? "+" : "-" }}
                    </button>
                  </legend>

                  <div v-show="!isCollapsed2">
                    <div class="modal-body">
                      <div class="form-group">
                        <label for="fileInput">Seleccionar Documento</label>
                        <div class="input-group">
                          <span
                            class="btn btn-success btn-sm fileinput-button dz-clickable text-center"
                            @click="triggerFileInput"
                          >
                            <i class="fas fa-plus"></i>
                            <span> Agregar Adjunto</span>
                            <input
                              type="file"
                              class="form-control-file"
                              id="fileInput"
                              accept=".pdf, .PDF, .xlsx, .JPG, .PNG, .docx"
                              ref="fileInput"
                              @change="handleFileChange"
                              style="display: none"
                            />
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            v-model="selectedFileName"
                            placeholder="No se ha seleccionado algun archivo"
                            readonly
                            style="pointer-events: none"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="tipoSoporteSelect">Tipo de Soporte</label>
                        <select
                          class="form-control"
                          id="tipoSoporteSelect"
                          v-model="tipoSoporte"
                          required
                        >
                          <option value="1">ANALISIS GPS</option>
                          <option value="2">REPORTE GPS</option>
                          <option value="3">FORMATO R-TC-TT-054</option>
                          <option value="4">OTRO</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="descripcionTextarea">Descripción</label>
                        <textarea
                          class="form-control"
                          id="descripcionTextarea"
                          rows="3"
                          v-model="descripcion"
                        ></textarea>
                      </div>
                      <button
                        type="button"
                        class="btn btn-lg btn-success"
                        @click="addDraftDocument"
                      >
                        Añadir a Borradores
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- Vista Documentos -->

              <div
                class="col-md-6 col-sm-12 col-xs-12"
                v-if="documentos != 0 || draftDocuments != 0"
                v-show="
                  $store.getters.can('hidrocarburos.faltantes.vistaDocumentos')
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Documentos
                    <button
                      @click="toggleCollapse3"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed3 ? "+" : "-" }}
                    </button>
                  </legend>

                  <div class="card-body" v-show="!isCollapsed3">
                    <div class="row justify-content-center">
                      <div
                        class="col-md-3"
                        v-for="(documento, index) in allDocuments"
                        :key="documento.key"
                      >
                        <div class="card">
                          <div
                            class="card-footer text-right p-1"
                            v-if="
                              documento.estado === 'Borrador' ||
                                (datos.cantidad_envios <= 3 &&
                                  datos.estado == 2 &&
                                  documento.estado !== 'Borrador')
                            "
                          >
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                v-if="documento.estado === 'Borrador'"
                                @click="removeDraftDocument(index)"
                              >
                                <i class="fas fa-trash"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                v-if="
                                  datos.cantidad_envios <= 3 &&
                                    datos.estado == 2 &&
                                    documento.estado !== 'Borrador'
                                "
                                @click="removeUploadedDocument(documento)"
                              >
                                <i class="fas fa-trash"></i>
                              </button>
                            </div>
                          </div>
                          <div class="card-body p-1">
                            <div class="row justfy-content-between">
                              <div class="col-4">
                                <button
                                  type="button"
                                  class="btn btn-success btn-sm"
                                  v-if="
                                    documento.estado !== 'Borrador' &&
                                      (documento.file_path || documento.link)
                                  "
                                  @click="downloadDocumento(documento)"
                                >
                                  <i class="fas fa-download"></i>
                                </button>
                              </div>
                              <div class="col-8 text-center text-xs text-muted">
                                <span>{{
                                  tipoSoporteTexto(documento.tipo_soporte)
                                }}</span>
                              </div>
                              <div class="col-12 text-xs text-muted">
                                <small>{{ documento.descripcion }}</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="modal-footer">
                      <button
                        v-if="
                          datos.cantidad_envios <= 3 &&
                            datos.estado == 2 &&
                            draftDocuments != 0
                        "
                        type="button"
                        class="btn btn-success"
                        @click="saveAllDocuments"
                      >
                        Guardar Documentos
                      </button>
                      <button
                        v-if="
                          (datos.cantidad_envios <= 1 &&
                            [2, 6].includes(datos.estado) &&
                            documentos != 0) ||
                            (datos.intento == 1 &&
                              datos.cantidad_envios <= 2 &&
                              [2, 6].includes(datos.estado) &&
                              documentos != 0)
                        "
                        type="button"
                        class="btn btn-primary"
                        @click="enviarJustificacion"
                      >
                        Enviar a Justificar
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- Justificacion Seguridad Fisica-->

              <div
                class="col-md-6 col-sm-12 col-xs-12"
                v-if="
                  datos.estado === 4 &&
                    datos.diferencias_guia_areas.some(
                      (ar) => ar.estado === 1 && ar.area === '1'
                    ) &&
                    $store.getters.can(
                      'hidrocarburos.faltantes.justificacionSeguridadFisica'
                    ) &&
                    datos.cantidad_envios >= 1 &&
                    datos.cantidad_envios <= 2
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Justificacion Seguridad Fisica
                    <button
                      @click="toggleCollapse5"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed5 ? "+" : "-" }}
                    </button>
                  </legend>
                  <div v-show="!isCollapsed5">
                    <div class="d-flex flex-column align-items-center">
                      <div class="form-group col-md-11">
                        <label for="CampoTextoSeguridad">Justificación</label>
                        <textarea
                          class="form-control"
                          id="CampoTextoSeguridad"
                          rows="3"
                          v-model="CampoTextoSeguridad"
                        ></textarea>
                      </div>
                      <!-- Cambio de estado -->
                      <div class="form-group col-md-11">
                        <label>Estado</label>
                        <select v-model="estadoSeguridad" class="form-control">
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        v-if="
                          datos.estado === 4 &&
                            estadoSeguridad != null &&
                            estadoSeguridad != '' &&
                            CampoTextoSeguridad != ''
                        "
                        type="button"
                        class="btn btn-primary"
                        @click="enviarRespuestaSeguridad()"
                      >
                        Enviar respuesta
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- Justificacion Medicion-->
              <div
                class="col-md-12 col-sm-12 col-xs-12"
                v-if="
                  datos.estado === 4 &&
                    datos.diferencias_guia_areas.some(
                      (ar) => ar.estado === 1 && ar.area === '3'
                    ) &&
                    $store.getters.can(
                      'hidrocarburos.faltantes.justificacionMediciones'
                    )
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Justificacion Mediciones
                    <button
                      @click="toggleCollapse7()"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed7 ? "+" : "-" }}
                    </button>
                  </legend>

                  <div v-show="!isCollapsed7">
                    <div class="modal-body">
                      <div
                        class="row justify-content-center"
                        v-if="viajes != null"
                      >
                        <div
                          class="col-lg-3 col-6 d-flex align-items-stretch flex-column"
                        >
                          <div class="small-box bg-gradient-danger">
                            <div class="inner textWhite">
                              <h3>
                                {{
                                  Object.values(cumpli).reduce(
                                    (total, anio) => {
                                      return (
                                        total + (anio.Faltante?.count || 0)
                                      );
                                    },
                                    0
                                  )
                                }}
                              </h3>
                              <p>Faltantes</p>
                            </div>
                            <div class="icon">
                              <i class="fas fa-times textWhite"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-3 col-6 d-flex align-items-stretch flex-column"
                        >
                          <div class="small-box bg-gradient-success">
                            <div class="inner textWhite">
                              <h3>
                                {{
                                  Object.keys(cumpli).reduce((total, anio) => {
                                    return (
                                      total +
                                      (cumpli[anio].Cumple
                                        ? cumpli[anio].Cumple.count
                                        : 0)
                                    );
                                  }, 0)
                                }}
                              </h3>
                              <p>Cumple</p>
                            </div>
                            <div class="icon">
                              <i class="fas fa-check textWhite"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-3 col-6 d-flex align-items-stretch flex-column"
                        >
                          <div class="small-box bg-sobrante">
                            <div class="inner textWhite">
                              <h3>
                                {{
                                  Object.values(cumpli).reduce(
                                    (total, anio) => {
                                      return (
                                        total + (anio.Sobrante?.count || 0)
                                      );
                                    },
                                    0
                                  )
                                }}
                              </h3>

                              <p>Sobrante</p>
                            </div>
                            <div class="icon">
                              <i class="fas fa-chart-line textWhite"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card card-info card-outline collapsed-card">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              id="tab-InfoViaje"
                              data-toggle="tab"
                              href="#InfoViaje"
                              >Justificacion</a
                            >
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="tab-Guias"
                              data-toggle="tab"
                              href="#Guias"
                              @click="consultarPlaca"
                              >Guias Descargadas</a
                            >
                          </li>
                          <li class="nav-item" v-show="datos.round_trip">
                            <a
                              class="nav-link"
                              id="tab-Round"
                              data-toggle="tab"
                              @click="consultarRound"
                              href="#Round"
                              >Round Trip</a
                            >
                          </li>
                          <li class="nav-item" v-show="datos.loteo">
                            <a
                              class="nav-link"
                              id="tab-Loteo"
                              data-toggle="tab"
                              @click="consultarLoteo"
                              href="#Loteo"
                              >Loteo</a
                            >
                          </li>
                        </ul>

                        <div class="tab-content" id="myTabContent">
                          <!-- Justificacion -->
                          <div class="tab-pane fade active show" id="InfoViaje">
                            <div class="modal-body">
                              <div class="row justify-content-center">
                                <div class="col-md-6 justify-content-center">
                                  <table
                                    class="table table-striped table-hover table-sm table-responsive text-center"
                                    v-for="infoGuia in datos.viaje.guia
                                      .det_guias"
                                    :key="infoGuia.id"
                                  >
                                    <tbody
                                      v-for="cierreGuia in datos.viaje.guia
                                        .cierre_guias"
                                      :key="cierreGuia.id"
                                    >
                                      <td
                                        rowspan="6"
                                        class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                                        style="text-align: center; vertical-align: middle;"
                                      >
                                        {{ infoGuia.producto_liquido.nombre }}
                                      </td>
                                      <td
                                        class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                                      ></td>
                                      <td
                                        class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                                      >
                                        <strong>Cargue</strong>
                                      </td>
                                      <td
                                        class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                                      >
                                        <strong>Descargue</strong>
                                      </td>
                                      <td
                                        class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                                      >
                                        <strong>Porcentaje</strong>
                                      </td>
                                      <td
                                        class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                                      >
                                        <strong>Diferencia</strong>
                                      </td>

                                      <tr>
                                        <td>API:</td>
                                        <td class="text-center">
                                          {{ infoGuia.api }}
                                        </td>
                                        <td>
                                          {{ cierreGuia.api }}
                                        </td>
                                        <td>
                                          {{ cierreGuia.diferencia_api
                                          }}<small>(%)</small>
                                        </td>
                                        <td>
                                          {{
                                            (
                                              cierreGuia.api - infoGuia.api
                                            ).toFixed(2)
                                          }}
                                        </td>
                                      </tr>
                                      <tr
                                        v-for="cierreGuia in datos.viaje.guia
                                          .cierre_guias"
                                        :key="cierreGuia.id"
                                      >
                                        <td>Vol. GOV:</td>
                                        <td class="text-center">
                                          {{ infoGuia.gov }}
                                        </td>
                                        <td>
                                          {{ cierreGuia.barriles_gov }}
                                        </td>
                                        <td>
                                          {{ cierreGuia.diferencia_gov
                                          }}<small>(%)</small>
                                        </td>
                                        <td>
                                          {{
                                            (
                                              cierreGuia.barriles_gov -
                                              infoGuia.gov
                                            ).toFixed(2)
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Vol. GSV:</td>
                                        <td class="text-center">
                                          {{ infoGuia.gsv }}
                                        </td>
                                        <td>
                                          {{ cierreGuia.barriles_gsv }}
                                        </td>
                                        <td>
                                          {{ cierreGuia.diferencia_gsv
                                          }}<small>(%)</small>
                                        </td>
                                        <td>
                                          {{
                                            (
                                              cierreGuia.barriles_gsv -
                                              infoGuia.gsv
                                            ).toFixed(2)
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Vol. NSV:</td>
                                        <td class="text-center">
                                          {{ infoGuia.nsv }}
                                        </td>
                                        <td>
                                          {{ cierreGuia.barriles_nsv }}
                                        </td>
                                        <td>
                                          {{ cierreGuia.diferencia_nsv
                                          }}<small>(%)</small>
                                        </td>
                                        <td>
                                          {{
                                            (
                                              cierreGuia.barriles_nsv -
                                              infoGuia.nsv
                                            ).toFixed(2)
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>BSW:</td>
                                        <td class="text-center">
                                          {{ infoGuia.bsw }}
                                        </td>
                                        <td>
                                          {{ cierreGuia.porcentaje_bsw }}
                                        </td>
                                        <td>
                                          {{ cierreGuia.diferencia_bsw
                                          }}<small>(%)</small>
                                        </td>
                                        <td>
                                          {{
                                            (
                                              cierreGuia.porcentaje_bsw -
                                              infoGuia.bsw
                                            ).toFixed(2)
                                          }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="form-group col-md-12">
                                      <label for="CampoTextoMedicion"
                                        >Justificación</label
                                      >
                                      <textarea
                                        class="form-control"
                                        id="CampoTextoMedicion"
                                        rows="3"
                                        v-model="CampoTextoMedicion"
                                      ></textarea>
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="form-group col-md-6">
                                      <label>Novedad</label>

                                      <select
                                        class="form-control"
                                        id="tipoNovedadSelect"
                                        v-model="novedadMedicion"
                                      >
                                        <option value="1">LOTEO</option>
                                        <option value="2">TRAZABILIDAD</option>
                                      </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                      <label>Estado</label>
                                      <select
                                        v-model="estadoMedicion"
                                        class="form-control"
                                      >
                                        <option value="">Seleccione...</option>
                                        <option
                                          v-for="estado in listasForms.estados"
                                          :key="estado.numeracion"
                                          :value="estado.numeracion"
                                        >
                                          {{ estado.descripcion }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="modal-footer"
                                v-if="
                                  this.CampoTextoMedicion != '' &&
                                    this.estadoMedicion != null &&
                                    this.estadoMedicion != ''
                                "
                              >
                                <button
                                  v-if="datos.estado === 4"
                                  type="button"
                                  class="btn btn-primary"
                                  @click="enviarRespuestaMedicion()"
                                >
                                  Enviar respuesta
                                </button>
                              </div>
                            </div>
                          </div>
                          <!-- Viajes -->
                          <div class="tab-pane fade" id="Guias">
                            <div class="modal-body">
                              <div class="row justify-content-center">
                                <div class="col-md-2">
                                  <div class="row">
                                    <button
                                      type="button"
                                      class="btn btn-md"
                                      :class="
                                        ver_filtros
                                          ? 'bg-olive'
                                          : 'bg-light text-muted'
                                      "
                                      @click="ver_filtros = !ver_filtros"
                                    >
                                      <i class="fas fa-filter"></i>
                                      <small>Filtros</small>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div v-if="ver_filtros">
                                <div class="modal-body">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <label>Descargue</label>

                                      <v-select
                                        :class="
                                          $store.getters.getDarkMode
                                            ? 'dark-vselect'
                                            : ''
                                        "
                                        v-model="filtros.sitioDescargue"
                                        :options="listasForms.sitios"
                                        label="nombre"
                                        :reduce="(sitio) => sitio.id"
                                        class="form-control form-control-sm p-0"
                                        :filterable="true"
                                      ></v-select>
                                    </div>

                                    <div class="col-md-2">
                                      <label>Mes</label>
                                      <select
                                        class="form-control form-control-sm"
                                        v-model="filtros.mes"
                                      >
                                        <option value="">Todos...</option>
                                        <option
                                          v-for="mes in listasForms.mes"
                                          :key="mes.numeracion"
                                          :value="mes.numeracion"
                                        >
                                          {{ mes.descripcion }}
                                        </option>
                                      </select>
                                    </div>

                                    <div class="col-md-3">
                                      <label>Cargue</label>
                                      <v-select
                                        :class="
                                          $store.getters.getDarkMode
                                            ? 'dark-vselect'
                                            : ''
                                        "
                                        v-model="filtros.sitioCargue"
                                        :options="listasForms.sitios"
                                        label="nombre"
                                        :reduce="(sitio) => sitio.id"
                                        class="form-control form-control-sm p-0"
                                        :filterable="true"
                                      ></v-select>
                                    </div>
                                    <div class="col-md-4">
                                      <label>Conductor</label>
                                      <select
                                        class="form-control form-control-sm"
                                        v-model="filtros.conductor"
                                      >
                                        <option value="">Todos...</option>
                                        <option
                                          v-for="conductores in conductores"
                                          :key="conductores.nombres"
                                          :value="conductores.id"
                                        >
                                          {{ conductores.nombres }}{{ " "
                                          }}{{ conductores.apellidos }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-1">
                                      <label>Año</label>
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="filtros.anio"
                                        maxlength="4"
                                        placeholder="Año"
                                      />
                                    </div>
                                    <div class="col-md-3">
                                      <label>Status</label>
                                      <select
                                        class="form-control form-control-sm"
                                        v-model="filtros.status"
                                      >
                                        <option value="">Todos...</option>
                                        <option value="cumple">Cumple</option>
                                        <option value="faltante"
                                          >Faltante</option
                                        >
                                        <option value="sobrante"
                                          >Sobrante</option
                                        >
                                      </select>
                                    </div>
                                    <div class="mt-4">
                                      <button
                                        class="btn btn-success"
                                        @click="consultarPlaca()"
                                      >
                                        Buscar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <!-- productos -->
                                <div class="col-md-6 col-sm-12 col-xs-12 pt-2">
                                  <div
                                    class="card card-primary card-outline collapsed-card"
                                  >
                                    <div class="card-header pt-2 pb-2">
                                      <h3 class="card-title">
                                        <i
                                          class="fas fa-oil-can text-secondary"
                                        ></i>
                                        Productos
                                      </h3>
                                      <div class="card-tools">
                                        <button
                                          type="button"
                                          data-card-widget="collapse"
                                          data-toggle="tooltip"
                                          title="Collapse"
                                          class="btn btn-tool"
                                          @click="
                                            ver_productos = !ver_productos
                                          "
                                        >
                                          <i class="fas fa-plus"></i>
                                        </button>
                                      </div>
                                    </div>
                                    <div class="card-body">
                                      <highcharts
                                        :options="productChartOptions"
                                        ref="productChart"
                                      />

                                      <table
                                        class="table table-hover text-nowrap table-sm table-auto table-responsive"
                                      >
                                        <thead
                                          class="bg-dark bg-frontera-top-left pt-2 pb-2 text-white text-center"
                                        >
                                          <tr>
                                            <th>Productos</th>
                                            <th>Cumple</th>
                                            <th>Faltante</th>
                                            <th>Sobrante</th>
                                            <th>Total</th>
                                            <th>% Cumplimiento</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="(valor, anio) in produc"
                                            :key="anio"
                                          >
                                            <td>
                                              <strong>
                                                {{ anio }}
                                              </strong>
                                            </td>
                                            <td>
                                              {{ valor.Cumple?.count || 0 }}
                                            </td>
                                            <td>
                                              {{ valor.Faltante?.count || 0 }}
                                            </td>
                                            <td>
                                              {{ valor.Sobrante?.count || 0 }}
                                            </td>
                                            <td>
                                              {{
                                                (valor.Sobrante?.count || 0) +
                                                  (valor.Faltante?.count || 0) +
                                                  (valor.Cumple?.count || 0)
                                              }}
                                            </td>
                                            <td
                                              class="bg-porcentaje text-porcentaje"
                                            >
                                              {{
                                                (
                                                  (((valor?.Sobrante?.count ||
                                                    0) +
                                                    (valor?.Cumple?.count ||
                                                      0)) /
                                                    ((valor?.Sobrante?.count ||
                                                      0) +
                                                      (valor?.Faltante?.count ||
                                                        0) +
                                                      (valor?.Cumple?.count ||
                                                        0) || 1)) *
                                                  100
                                                ).toFixed(2)
                                              }}%
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <!-- Destinos -->
                                <div class="col-md-6 col-sm-12 col-xs-12 pt-2">
                                  <div
                                    class="card card-info card-outline collapsed-card"
                                  >
                                    <div class="card-header pt-2 pb-2">
                                      <h3 class="card-title">
                                        <i class="fa fa-map text-secondary"></i>
                                        Destinos
                                      </h3>
                                      <div class="card-tools">
                                        <button
                                          type="button"
                                          data-card-widget="collapse"
                                          data-toggle="tooltip"
                                          title="Collapse"
                                          class="btn btn-tool"
                                        >
                                          <i class="fas fa-plus"></i>
                                        </button>
                                      </div>
                                    </div>
                                    <div class="card-body">
                                      <highcharts
                                        :options="chartOptions"
                                        ref="chart"
                                      />
                                      <table
                                        class="table text-center table-hover text-nowrap table-sm table-auto table-responsive"
                                      >
                                        <thead
                                          class="bg-dark bg-frontera-top-left pt-2 pb-2 text-white text-center"
                                        >
                                          <tr>
                                            <th>Descargue</th>
                                            <th>Cumple</th>
                                            <th>Faltante</th>
                                            <th>Sobrante</th>
                                            <th>Total</th>
                                            <th>% Cumplimiento</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="(valor, anio) in dest"
                                            :key="anio"
                                          >
                                            <td>
                                              <strong>
                                                {{ anio }}
                                              </strong>
                                            </td>
                                            <td>
                                              {{ valor.Cumple?.count || 0 }}
                                            </td>
                                            <td>
                                              {{ valor.Faltante?.count || 0 }}
                                            </td>
                                            <td>
                                              {{ valor.Sobrante?.count || 0 }}
                                            </td>
                                            <td>
                                              {{
                                                (valor.Sobrante?.count || 0) +
                                                  (valor.Faltante?.count || 0) +
                                                  (valor.Cumple?.count || 0)
                                              }}
                                            </td>
                                            <td
                                              class="bg-porcentaje text-porcentaje"
                                            >
                                              {{
                                                (
                                                  (((valor?.Sobrante?.count ||
                                                    0) +
                                                    (valor?.Cumple?.count ||
                                                      0)) /
                                                    ((valor?.Sobrante?.count ||
                                                      0) +
                                                      (valor?.Faltante?.count ||
                                                        0) +
                                                      (valor?.Cumple?.count ||
                                                        0) || 1)) *
                                                  100
                                                ).toFixed(2)
                                              }}%
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- Tabla general de viajes -->
                              <table
                                class="table table-bordered table-hover text-nowrap table-sm table-auto table-responsive"
                              >
                                <thead
                                  class="bg-dark bg-frontera-top-left pt-2 pb-2 text-white text-center"
                                >
                                  <tr>
                                    <th>Descargue</th>
                                    <th>Mes</th>
                                    <th>Cargue</th>
                                    <th>Dia</th>
                                    <th>Cargue</th>
                                    <th>Descargue</th>
                                    <th>Guia</th>
                                    <th>Producto</th>
                                    <th>Empresa</th>
                                    <th>Vehiculo</th>
                                    <th>Conductor</th>
                                    <th>Guia API</th>
                                    <th>Guia SW</th>
                                    <th>Guia GOV</th>
                                    <th>Guia GSV</th>
                                    <th>Guia NSV</th>
                                    <th>API</th>
                                    <th>SW</th>
                                    <th>GSV</th>
                                    <th>NSV</th>
                                    <th>DIF</th>
                                    <th>%</th>
                                    <th>Status</th>
                                    <th>Novedad</th>
                                    <th>Año</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="viajePlaca in viajes.data"
                                    :key="viajePlaca.id"
                                    :class="{
                                      'bg-cumple':
                                        viajePlaca.cierre_guias[0] &&
                                        viajePlaca.diferencia >=
                                          -viajePlaca.det_guias[0]
                                            .producto_liquido
                                            .tolerancia_perdidas &&
                                        viajePlaca.diferencia <=
                                          viajePlaca.det_guias[0]
                                            .producto_liquido
                                            .tolerancia_perdidas,
                                      'bg-faltante':
                                        viajePlaca.cierre_guias[0] &&
                                        viajePlaca.diferencia <
                                          -viajePlaca.det_guias[0]
                                            .producto_liquido
                                            .tolerancia_perdidas,
                                      'bg-sobrante':
                                        viajePlaca.cierre_guias[0] &&
                                        viajePlaca.diferencia >
                                          viajePlaca.det_guias[0]
                                            .producto_liquido
                                            .tolerancia_perdidas,
                                    }"
                                  >
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0].sitio_destino
                                          ? viajePlaca.det_guias[0]
                                              .sitio_destino.nombre
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.cierre_guias.length
                                          ? (
                                              listasForms.mes.find(
                                                (mes) =>
                                                  mes.numeracion ===
                                                  new Date(
                                                    viajePlaca.cierre_guias[0].fecha_hora_descargue
                                                  ).getMonth() +
                                                    1
                                              ) || {}
                                            ).descripcion || ""
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0].sitio_origen
                                          ? viajePlaca.det_guias[0].sitio_origen
                                              .nombre
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.cierre_guias &&
                                        viajePlaca.cierre_guias.length
                                          ? new Date(
                                              viajePlaca.cierre_guias[0].fecha_hora_descargue
                                            ).getDate()
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0] &&
                                        viajePlaca.det_guias[0].fecha_expedicion
                                          ? `${new Date(
                                              viajePlaca.det_guias[0].fecha_expedicion
                                            ).getFullYear()}-${(
                                              new Date(
                                                viajePlaca.det_guias[0].fecha_expedicion
                                              ).getMonth() + 1
                                            )
                                              .toString()
                                              .padStart(2, "0")}-${new Date(
                                              viajePlaca.det_guias[0].fecha_expedicion
                                            )
                                              .getDate()
                                              .toString()
                                              .padStart(2, "0")}`
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.cierre_guias &&
                                        viajePlaca.cierre_guias.length
                                          ? `${new Date(
                                              viajePlaca.cierre_guias[0].fecha_hora_descargue
                                            ).getFullYear()}-${(
                                              new Date(
                                                viajePlaca.cierre_guias[0].fecha_hora_descargue
                                              ).getMonth() + 1
                                            )
                                              .toString()
                                              .padStart(2, "0")}-${new Date(
                                              viajePlaca.cierre_guias[0].fecha_hora_descargue
                                            )
                                              .getDate()
                                              .toString()
                                              .padStart(2, "0")}`
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0].guia.numero
                                      }}-{{
                                        viajePlaca.det_guias[0].guia
                                          .digito_verificacion
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0].producto_liquido
                                          ? viajePlaca.det_guias[0]
                                              .producto_liquido.nombre
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0].transportadora
                                          ? viajePlaca.det_guias[0]
                                              .transportadora.razon_social
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0].vehiculo
                                          ? viajePlaca.det_guias[0].vehiculo
                                              .placa
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0].conductor
                                          ? `${viajePlaca.det_guias[0].conductor.nombres} ${viajePlaca.det_guias[0].conductor.apellidos}`
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0]
                                          ? viajePlaca.det_guias[0].api
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0]
                                          ? viajePlaca.det_guias[0].bsw
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0]
                                          ? viajePlaca.det_guias[0].gov
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0]
                                          ? viajePlaca.det_guias[0].gsv
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.det_guias[0]
                                          ? viajePlaca.det_guias[0].nsv
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.cierre_guias.length
                                          ? viajePlaca.cierre_guias[0].api
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.cierre_guias.length
                                          ? viajePlaca.cierre_guias[0]
                                              .porcentaje_bsw
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.cierre_guias.length
                                          ? viajePlaca.cierre_guias[0]
                                              .barriles_gsv
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.cierre_guias.length
                                          ? viajePlaca.cierre_guias[0]
                                              .barriles_nsv
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca.cierre_guias &&
                                        viajePlaca.cierre_guias.length
                                          ? viajePlaca.diferencia.toFixed(2)
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        viajePlaca &&
                                        viajePlaca.cierre_guias &&
                                        viajePlaca.cierre_guias.length
                                          ? viajePlaca.cierre_guias[0]
                                              .diferencia_nsv
                                          : ""
                                      }}<small>(%)</small>
                                    </td>
                                    <td v-if="viajePlaca.cierre_guias.length">
                                      <span
                                        v-if="
                                          parseFloat(viajePlaca.diferencia) <
                                            parseFloat(
                                              -viajePlaca.det_guias[0]
                                                .producto_liquido
                                                .tolerancia_perdidas
                                            )
                                        "
                                        class="text-faltante"
                                      >
                                        Faltante
                                      </span>
                                      <span
                                        v-if="
                                          parseFloat(viajePlaca.diferencia) >=
                                            parseFloat(
                                              -viajePlaca.det_guias[0]
                                                .producto_liquido
                                                .tolerancia_perdidas
                                            ) &&
                                            parseFloat(viajePlaca.diferencia) <=
                                              parseFloat(
                                                viajePlaca.det_guias[0]
                                                  .producto_liquido
                                                  .tolerancia_perdidas
                                              )
                                        "
                                        class="text-cumple"
                                      >
                                        Cumple
                                      </span>

                                      <span
                                        v-if="
                                          parseFloat(viajePlaca.diferencia) >
                                            parseFloat(
                                              viajePlaca.det_guias[0]
                                                .producto_liquido
                                                .tolerancia_perdidas
                                            )
                                        "
                                        class="text-sobrante"
                                      >
                                        Sobrante
                                      </span>
                                    </td>
                                    <td></td>
                                    <td>
                                      {{
                                        viajePlaca.cierre_guias &&
                                        viajePlaca.cierre_guias.length
                                          ? new Date(
                                              viajePlaca.cierre_guias[0].fecha_hora_descargue
                                            ).getFullYear()
                                          : ""
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="col-md-5">
                                <div class="float-left" v-if="viajes.total">
                                  <p>
                                    Mostrando del <b>{{ viajes.from }}</b> al
                                    <b>{{ viajes.to }}</b> de un total:
                                    <b>{{ viajes.total }}</b> Registros
                                  </p>
                                </div>
                                <div class="float-left" v-else>
                                  <p>
                                    <span class="badge badge-danger">
                                      No hay registros para mostrar
                                    </span>
                                  </p>
                                </div>
                                <pagination
                                  :data="viajes"
                                  @pagination-change-page="consultarPlaca"
                                  :limit="5"
                                  class="text-right"
                                >
                                </pagination>
                              </div>
                            </div>
                          </div>
                          <!-- Round Trip -->
                          <div class="tab-pane fade" id="Round">
                            <div
                              class="card mx-auto"
                              style="max-width: 90%; margin-top: 20px;"
                            >
                              <table
                                class="table table-hover text-center table-bordered table-striped table-responsive"
                              >
                                <thead
                                  class="bg-dark bg-frontera-top-left pt-2 pb-2 text-white text-center"
                                >
                                  <tr>
                                    <th>Numero de guia</th>
                                    <th>Sitio cargue</th>
                                    <th>Sitio Descargue</th>
                                    <th>Fecha de Expedicion</th>
                                    <th>Nsv Cargue</th>
                                    <th>Nsv Descargue</th>
                                    <th>Diferencia NSV</th>
                                    <th>Api</th>
                                    <th>Promedio NSV</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {{ datos?.viaje?.guia?.numero || ""
                                      }}{{
                                        datos?.viaje?.guia
                                          ?.digito_verificacion || ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        datos?.viaje?.guia?.det_guias?.[0]
                                          ?.sitio_origen?.nombre || "N/A"
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        datos?.viaje?.guia?.det_guias?.[0]
                                          ?.sitio_destino?.nombre || "N/A"
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        datos?.viaje?.guia?.det_guias?.[0]
                                          ?.fecha_expedicion || "N/A"
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        datos?.viaje?.guia?.det_guias?.[0]
                                          ?.nsv ?? 0
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        datos?.viaje?.guia?.cierre_guias?.[0]
                                          ?.barriles_nsv ?? 0
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        (datos?.viaje?.guia?.cierre_guias?.[0]
                                          ?.barriles_nsv ?? 0) -
                                          (datos?.viaje?.guia?.det_guias?.[0]
                                            ?.nsv ?? 0)
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        datos?.viaje?.guia?.cierre_guias?.[0]
                                          ?.api ?? "N/A"
                                      }}
                                    </td>
                                    <td rowspan="2" class="align-middle">
                                      {{
                                        (
                                          ((datos?.viaje?.guia
                                            ?.cierre_guias?.[0]?.barriles_nsv ??
                                            0) -
                                            (datos?.viaje?.guia?.det_guias?.[0]
                                              ?.nsv ?? 0) +
                                            ((round?.guia?.cierre_guias?.[0]
                                              ?.barriles_nsv ?? 0) -
                                              (round?.guia?.det_guias?.[0]
                                                ?.nsv ?? 0))) /
                                          2
                                        ).toFixed(2)
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {{ round?.guia?.numero || ""
                                      }}{{
                                        round?.guia?.digito_verificacion || ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        round?.guia?.det_guias?.[0]
                                          ?.sitio_origen?.nombre || "N/A"
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        round?.guia?.det_guias?.[0]
                                          ?.sitio_destino?.nombre || "N/A"
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        round?.guia?.det_guias?.[0]
                                          ?.fecha_expedicion || "N/A"
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        round?.guia?.det_guias?.[0]?.nsv ?? 0
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        round?.guia?.cierre_guias?.[0]
                                          ?.barriles_nsv ?? 0
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        (round?.guia?.cierre_guias?.[0]
                                          ?.barriles_nsv ?? 0) -
                                          (round?.guia?.det_guias?.[0]?.nsv ??
                                            0)
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        round?.guia?.cierre_guias?.[0]?.api ??
                                          "N/A"
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <!-- Loteo -->
                          <div class="tab-pane fade" id="Loteo">
                            <div
                              class="card mx-auto"
                              style="max-width: 90%; margin-top: 20px;"
                            >
                              <table
                                class="table table-hover text-center table-bordered table-striped table-responsive"
                              >
                                <thead
                                  class="bg-dark bg-frontera-top-left pt-2 pb-2 text-white text-center"
                                >
                                  <tr>
                                    <th>Guia</th>
                                    <th>Numero de guia</th>
                                    <th>Placa</th>
                                    <th>Sitio cargue</th>
                                    <th>Sitio Descargue</th>
                                    <th>Fecha de Expedicion</th>
                                    <th>Nsv Cargue</th>
                                    <th>Nsv Descargue</th>
                                    <th>Diferencia NSV</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Guia Anterior</td>
                                    <td>
                                      {{ loteoAn?.numero || ""
                                      }}{{ loteoAn?.digito_verificacion || "" }}
                                    </td>
                                    <td>
                                      {{ loteoAn?.det_guias[0].vehiculo.placa }}
                                    </td>
                                    <td>
                                      {{
                                        loteoAn?.det_guias[0].sitio_origen
                                          .nombre
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        loteoAn?.det_guias[0].sitio_destino
                                          .nombre
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        loteoAn?.det_guias[0].fecha_expedicion
                                      }}
                                    </td>
                                    <td>
                                      {{ loteoAn?.det_guias[0].nsv }}
                                    </td>
                                    <td>
                                      {{
                                        loteoAn?.cierre_guias[0].barriles_nsv
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        loteoAn?.cierre_guias[0].barriles_nsv -
                                          loteoAn?.det_guias[0].nsv
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Guia Actual</td>
                                    <td>
                                      {{ datos?.viaje.guia.numero || ""
                                      }}{{
                                        datos?.viaje.guia.digito_verificacion ||
                                          ""
                                      }}
                                    </td>
                                    <td>{{ datos?.viaje.vehiculo.placa }}</td>

                                    <td>
                                      {{
                                        datos?.viaje.guia.det_guias[0]
                                          .sitio_origen.nombre
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        datos?.viaje.guia.det_guias[0]
                                          .sitio_destino.nombre
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        datos?.viaje.guia.det_guias[0]
                                          .fecha_expedicion
                                      }}
                                    </td>
                                    <td>
                                      {{ datos?.viaje.guia.det_guias[0].nsv }}
                                    </td>
                                    <td>
                                      {{
                                        datos?.viaje.guia.cierre_guias[0]
                                          .barriles_nsv
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        datos?.viaje.guia.cierre_guias[0]
                                          .barriles_nsv -
                                          datos?.viaje.guia.det_guias[0].nsv
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Guia Anterior</td>
                                    <td>
                                      {{ loteoSi?.numero || ""
                                      }}{{ loteoSi?.digito_verificacion || "" }}
                                    </td>
                                    <td>
                                      {{ loteoSi?.det_guias[0].vehiculo.placa }}
                                    </td>

                                    <td>
                                      {{
                                        loteoSi?.det_guias[0].sitio_origen
                                          .nombre
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        loteoSi?.det_guias[0].sitio_destino
                                          .nombre
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        loteoSi?.det_guias[0].fecha_expedicion
                                      }}
                                    </td>
                                    <td>
                                      {{ loteoSi?.det_guias[0].nsv }}
                                    </td>
                                    <td>
                                      {{
                                        loteoSi?.cierre_guias[0].barriles_nsv
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        loteoSi?.cierre_guias[0].barriles_nsv -
                                          loteoSi?.det_guias[0].nsv
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- Justificacion Trasnporte-->
              <div
                class="col-md-6 col-sm-12 col-xs-12"
                v-if="
                  datos.estado === 4 &&
                    datos.diferencias_guia_areas.some(
                      (ar) => ar.estado === 1 && ar.area === '2'
                    ) &&
                    $store.getters.can(
                      'hidrocarburos.faltantes.justificacionTransporte'
                    )
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Justificacion Transporte
                    <button
                      @click="toggleCollapse8"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed8 ? "+" : "-" }}
                    </button>
                  </legend>

                  <div v-show="!isCollapsed8">
                    <div class="d-flex flex-column align-items-center">
                      <div class="form-group col-md-11">
                        <label for="CampoTextoTransporte">Justificación</label>
                        <textarea
                          class="form-control"
                          id="CampoTextoTransporte"
                          rows="3"
                          v-model="CampoTextoTransporte"
                        ></textarea>
                      </div>
                      <!-- Cambio de estado -->
                      <div class="form-group col-md-11">
                        <label>Estado</label>
                        <select v-model="estadoTransporte" class="form-control">
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        v-if="
                          datos.estado === 4 &&
                            CampoTextoTransporte != '' &&
                            estadoTransporte != null &&
                            estadoTransporte != ''
                        "
                        type="button"
                        class="btn btn-primary"
                        @click="enviarRespuestaTransporte()"
                      >
                        Enviar respuesta
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
              <!-- Asignacion de areas -->
              <div
                class="col-md-12 col-sm-12 col-xs-12"
                v-if="
                  datos.estado === 3 &&
                    $store.getters.can('hidrocarburos.faltantes.asignarArea') &&
                    datos.cantidad_envios >= 1 &&
                    datos.cantidad_envios <= 2
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Area Encargada
                    <button
                      @click="toggleCollapse4"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed4 ? "+" : "-" }}
                    </button>
                  </legend>

                  <div v-show="!isCollapsed4">
                    <div class="row pt-2 mx-2">
                      <div class="form-group col-md-6">
                        <label>Área de aceptación</label>
                        <v-select
                          v-model="areaSeleccionada"
                          class="form-control form-control-sm p-0"
                          placeholder="Nombre"
                          label="descripcion"
                          multiple
                          :options="listasForms.areas"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="flete">Valor Flete</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="flete"
                            v-model="flete"
                            required
                          />
                        </div>
                      </div>
                      <div
                        class="form-group col-md-12 text-center"
                        v-show="this.flete"
                      >
                        <h5>Tarifa Transporte</h5>
                        <table
                          class="table table-bordered table-sm mx-auto"
                          style="width: 50%;"
                        >
                          <thead class="thead-light">
                            <tr>
                              <th>Valor Flete</th>
                              <th>*</th>
                              <th>42</th>
                              <th>*</th>
                              <th>Diferencia NSV</th>
                              <th>=</th>
                              <th>Cálculo</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                ${{
                                  Number(flete).toLocaleString("es-CO", {
                                    minimumFractionDigits: 2,
                                  })
                                }}
                              </td>
                              <td>*</td>
                              <td>42</td>
                              <td>*</td>
                              <td>
                                {{
                                  Number(datos.nsv_diferencia).toLocaleString(
                                    "es-CO",
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )
                                }}
                              </td>
                              <td>=</td>
                              <td class="text-primary">
                                ${{
                                  Number(calculo).toLocaleString("es-CO", {
                                    minimumFractionDigits: 2,
                                  })
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      class="modal-footer"
                      v-if="
                        datos.estado === 3 && areaSeleccionada != 0 && calculo
                      "
                    >
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="enviarAreaEncargada()"
                      >
                        Enviar al area encargada
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- Asignar administrador de contrato -->
              <div
                class="col-md-12 col-sm-12 col-xs-12"
                v-if="
                  datos.estado === 3 &&
                    $store.getters.can('hidrocarburos.faltantes.asignarArea') &&
                    datos.cantidad_envios === 3
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Asignar Administrador Contrato
                    <button
                      @click="toggleCollapse4"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed10 ? "+" : "-" }}
                    </button>
                  </legend>

                  <div v-show="!isCollapsed10" class="card-body text-s">
                    <div class="row justify-content-center">
                      <div class="form-group col-md-6">
                        <label>Funcionarios</label>
                        <v-select
                          v-model="funcionarioSeleccionada"
                          class="form-control form-control-sm p-0"
                          placeholder="Nombre"
                          label="funcionario"
                          multiple
                          :options="$parent.listasForms.funcionarios"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-6">
                        <table
                          class="class=table table-bordered table-striped table-hover table-sm"
                          v-if="funcionarioSeleccionada != 0"
                        >
                          <tr>
                            <th>Funcionario</th>
                            <th>Correo</th>
                          </tr>
                          <tbody>
                            <tr
                              v-for="funcionarioSeleccionada in funcionarioSeleccionada"
                              :key="funcionarioSeleccionada.id"
                            >
                              <td>
                                {{ funcionarioSeleccionada.funcionario }}
                              </td>
                              <td>
                                {{ funcionarioSeleccionada.email }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        v-if="
                          datos.estado === 3 && funcionarioSeleccionada != 0
                        "
                        type="button"
                        class="btn btn-primary"
                        @click="enviarAdminContrato()"
                      >
                        Enviar Correo
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <!-- Confirmador admin Contratos -->

            <div
              class="row"
              v-if="
                datos.estado == 9 &&
                  $store.getters.can('hidrocarburos.faltantes.adminContratos')
              "
            >
              <div class="col-md-12 col-sm-12 col-xs-12">
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Confirmador
                    <button
                      @click="toggleCollapse9"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed9 ? "+" : "-" }}
                    </button>
                  </legend>
                  <div class="tab-pane" v-show="!isCollapsed9">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-6 col-sm-12 col-xs-12">
                          <label>Estado</label>
                          <select
                            v-model="estadoConfirmador"
                            class="form-control"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>

                          <label for="CampoTextoConfirmador"
                            >Justificación</label
                          >
                          <textarea
                            class="form-control"
                            id="CampoTextoConfirmador"
                            rows="3"
                            v-model="CampoTextoConfirmador"
                          ></textarea>
                        </div>
                        <div class="col-md-6 col-sm-12 col-xs-12">
                          <table
                            class="table table-bordered table-hover text-nowrap table-sm"
                          >
                            <thead class="bg-dark">
                              <tr>
                                <th>Area</th>
                                <th>Usuraio</th>
                                <th>N° Replica</th>
                                <th>Justificacion</th>
                                <th>Estado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in datos.diferencias_guia_areas"
                                :key="item.id"
                              >
                                <td>{{ item.Narea }}</td>
                                <td>
                                  {{ item.usuario ? item.usuario.name : "" }}
                                </td>
                                <td class="text-center">
                                  {{ item.intento }}
                                </td>
                                <td>{{ item.respuesta }}</td>
                                <td>
                                  {{
                                    item.estado === 1
                                      ? "Pendiente"
                                      : item.estado === 2
                                      ? "Cobrado"
                                      : item.estado === 3
                                      ? "No Cobrado"
                                      : ""
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        v-if="datos.estado === 9 && estadoConfirmador != null"
                        type="button"
                        class="btn btn-primary"
                        @click="enviarRespuestaConfirmador()"
                      >
                        Enviar respuesta
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <!-- Confirmador -->

            <div
              class="row"
              v-if="
                datos.confirmador == 1 &&
                  datos.estado == 7 &&
                  $store.getters.can('hidrocarburos.faltantes.confirmador')
              "
            >
              <div class="col-md-12 col-sm-12 col-xs-12">
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Confirmador
                    <button
                      @click="toggleCollapse9"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed9 ? "+" : "-" }}
                    </button>
                  </legend>
                  <div class="tab-pane" v-show="!isCollapsed9">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-6 col-sm-12 col-xs-12">
                          <label>Estado</label>
                          <select
                            v-model="estadoConfirmador"
                            class="form-control"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>

                          <label for="CampoTextoConfirmador"
                            >Justificación</label
                          >
                          <textarea
                            class="form-control"
                            id="CampoTextoConfirmador"
                            rows="3"
                            v-model="CampoTextoConfirmador"
                          ></textarea>
                        </div>

                        <div class="col-md-6 col-sm-12 col-xs-12">
                          <table
                            class="table table-bordered table-hover text-nowrap table-sm table-responsive"
                          >
                            <thead class="bg-dark">
                              <tr>
                                <th>Area</th>
                                <th>Usuraio</th>
                                <th>N° Replica</th>
                                <th>Justificacion</th>
                                <th>Estado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in datos.diferencias_guia_areas"
                                :key="item.id"
                              >
                                <td>{{ item.Narea }}</td>
                                <td>
                                  {{ item.usuario ? item.usuario.name : "" }}
                                </td>
                                <td class="text-center">
                                  {{ item.intento }}
                                </td>
                                <td>{{ item.respuesta }}</td>
                                <td>
                                  {{
                                    item.estado === 1
                                      ? "Pendiente"
                                      : item.estado === 2
                                      ? "Cobrado"
                                      : item.estado === 3
                                      ? "No Cobrado"
                                      : ""
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        v-if="
                          datos.estado === 7 &&
                            CampoTextoConfirmador != '' &&
                            estadoConfirmador != null &&
                            estadoConfirmador != ''
                        "
                        type="button"
                        class="btn btn-primary"
                        @click="enviarRespuestaConfirmador()"
                      >
                        Enviar respuesta
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          <!-- Mapa -->

          <div
            class="modal-body"
            v-if="$store.getters.can('hidrocarburos.faltantes.mapa')"
          >
            <fieldset class="well-card rounded border border-primary">
              <legend
                class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
              >
                Mapa
                <button
                  @click="toggleCollapse6"
                  class="btn-toggle small-button"
                >
                  {{ isCollapsed6 ? "+" : "-" }}
                </button>
              </legend>
              <div class="tab-pane" v-show="!isCollapsed6">
                <div class="card-body">
                  <div>
                    <FaltantesMapa ref="FaltantesMapa" />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import FaltantesMapa from "./FaltantesMapa";
import pagination from "laravel-vue-pagination";
import { Chart } from "highcharts-vue";

export default {
  name: "FaltantesEscanerDocumento",
  components: {
    FaltantesMapa,
    vSelect,
    highcharts: Chart,
    pagination,
  },
  /*  props: {
    dest: {
      type: Object,
      required: true
    }
  }, */
  data() {
    return {
      cargando: false,
      isCollapsed: false,
      isCollapsed2: false,
      isCollapsed3: false,
      isCollapsed4: false,
      isCollapsed5: false,
      isCollapsed6: false,
      isCollapsed7: false,
      isCollapsed8: false,
      isCollapsed9: false,
      isCollapsed10: false,
      isCollapsedTable2: false,
      isCollapsedFilter: false,
      isCollapsedGraficas: false,
      selectedFileName: "",
      conductores: [],
      progress: null,
      file: null,
      viajes: [],
      cumpli: [],
      produc: null,
      dest: [],
      round: null,
      loteoAn: null,
      loteoSi: null,
      calculo: null,
      tipoSoporte: "1",
      descripcion: "",
      CampoTextoSeguridad: "",
      CampoTextoMedicion: "",
      CampoTextoTransporte: "",
      CampoTextoConfirmador: "",
      diferencia_guia_notificacione_cobro_id: null,
      documentos: [],
      draftDocuments: [],
      loading: false,
      estadoNotificacion: null,
      estadoSeguridad: null,
      estadoMedicion: null,
      novedadMedicion: null,
      estadoTransporte: null,
      confirmador: null,
      estadoConfirmador: null,
      datos: [],
      areaSeleccionada: "",
      flete: null,
      funcionarioSeleccionada: "",
      // uri_docs: "http://localhost:8000/",
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      chartOptions: {},
      productChartOptions: {},
      listasForms: {
        areas: [],
        mes: [],
        sitios: [],
        estados: [
          { numeracion: 2, descripcion: "COBRO" },
          { numeracion: 3, descripcion: "NO COBRO" },
        ],
      },
      ver_filtros: false,
      ver_productos: false,
      filtros: {
        mes: null,
        sitioDescargue: null,
        sitioCargue: null,
        dia: null,
        producto: null,
        conductor: null,
        status: null,
        anio: null,
      },
    };
  },

  computed: {
    estadoDeshabilitado() {
      return this.estadoNotificacion === 3;
    },
    allDocuments() {
      return [
        ...this.draftDocuments.map((doc, index) => ({
          ...doc,
          key: "draft" + index,
        })),
        ...this.documentos.map((doc, index) => ({
          ...doc,
          key: "uploaded" + index,
        })),
      ];
    },
  },
  watch: {
    flete(newValue) {
      this.calcularFlete();
    },
  },

  methods: {
    calcularFlete() {
      if (this.flete > 0 && this.datos.nsv_diferencia > 0) {
        this.calculo = this.flete * 42 * this.datos.nsv_diferencia;
      } else {
        this.calculo = null;
      }
    },

    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    // Cargue Documentos
    toggleCollapse2() {
      this.isCollapsed2 = !this.isCollapsed2;
    },
    // Vista Documentos
    toggleCollapse3() {
      this.isCollapsed3 = !this.isCollapsed3;
    },
    // Area
    toggleCollapse4() {
      this.isCollapsed4 = !this.isCollapsed4;
    },
    // Justificacion
    toggleCollapse5() {
      this.isCollapsed5 = !this.isCollapsed5;
    },
    toggleCollapse7() {
      this.isCollapsed7 = !this.isCollapsed7;
    },
    toggleCollapse8() {
      this.isCollapsed8 = !this.isCollapsed8;
    },
    // Mapa
    toggleCollapse6() {
      this.isCollapsed6 = !this.isCollapsed6;
    },
    // Confirmador
    toggleCollapse9() {
      this.isCollapsed9 = !this.isCollapsed9;
    },
    toggleCollapse10() {
      this.isCollapsed10 = !this.isCollapsed10;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    //Listas
    getAreas() {
      axios.get("/api/lista/207").then((response) => {
        this.listasForms.areas = response.data;
      });
    },
    getEstados() {
      axios.get("/api/lista/152").then((response) => {
        this.listasForms.mes = response.data;
      });
    },
    getSitios() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    handleFileChange(event) {
      this.file = event.target.files[0];
      this.selectedFileName = this.file.name;
    },
    addDraftDocument() {
      if (!this.file) {
        this.$swal({
          icon: "error",
          title: "Por favor selecciona un documento",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.draftDocuments.push({
          file: this.file,
          tipo_soporte: this.tipoSoporte,
          descripcion: this.descripcion,
          estado: "Borrador",
          preview: e.target.result,
        });
        this.file = null;
        this.tipoSoporte = "1";
        this.descripcion = "";
        this.selectedFileName = "";
        this.$refs.fileInput.value = null;
      };
      reader.readAsDataURL(this.file);
    },
    removeDraftDocument(index) {
      this.draftDocuments.splice(index, 1);
    },
    saveAllDocuments() {
      var vm = this;
      vm.loading = true;

      let formData = new FormData();
      this.draftDocuments.forEach((documento, index) => {
        formData.append(`documentos[${index}][documento]`, documento.file);
        formData.append(
          `documentos[${index}][tipo_soporte]`,
          documento.tipo_soporte
        );
        formData.append(
          `documentos[${index}][descripcion]`,
          documento.descripcion
        );
        formData.append(`documentos[${index}][estado]`, "Pendiente");
        formData.append(
          `documentos[${index}][diferencia_guia_notificacione_cobro_id]`,
          this.diferencia_guia_notificacione_cobro_id
        );
      });

      axios
        .post("/api/hidrocarburos/subir_documentos", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            vm.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((response) => {
          this.documentos = this.documentos.concat(response.data);
          this.draftDocuments = [];
          this.$swal({
            icon: "success",
            title: "Todos los documentos se han subido exitosamente",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          vm.loading = false;
          /*           this.$parent.getIndex();
          this.$refs.closeModal.click(); */
        })

        .catch((error) => {
          console.error(error);
          this.$swal({
            icon: "error",
            title: "Hubo un error al subir los documentos",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          vm.loading = false;
        });
    },
    downloadDocumento(documento) {
      const documentUrl = `${this.uri_docs}${documento.file_path ||
        documento.link}`;
      if (documentUrl) {
        window.open(documentUrl, "_blank");
      } else {
        console.error("El documento no tiene un link definido.");
        this.$swal({
          icon: "error",
          title: "No se pudo descargar el documento",
          text: "El documento no tiene un link definido.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
    tipoSoporteTexto(tipo) {
      const numTipo = Number(tipo);
      switch (numTipo) {
        case 1:
          return "ANALISIS GPS";
        case 2:
          return "REPORTE GPS";
        case 3:
          return "FORMATO R-TC-TT-054";
        case 4:
          return "OTRO";
        default:
          return "";
      }
    },
    estadoTexto(estado) {
      switch (estado) {
        case "Borrador":
          return "Borrador";
        case "Pendiente":
          return "Pendiente";
        case 1:
          return "Gestionado";
        case 2:
          return "Verificado";
        default:
          return "";
      }
    },

    llenar_modal_documento(faltante) {
      this.$parent.cargando = true;

      this.diferencia_guia_notificacione_cobro_id = faltante.id;
      axios
        .get("/api/hidrocarburos/obtener_documentos/" + faltante.id)
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.documentos = response.data.map((documento) => {
              return {
                ...documento,
                estado:
                  documento.estado === 1
                    ? "Gestionado"
                    : documento.estado === 2
                    ? "Verificado"
                    : "Pendiente",
                file_path: documento.link || "",
              };
            });
            this.datos = faltante;
            this.estadoNotificacion = faltante.estado;
          }
          this.$parent.cargando = false;
        })
        .catch((error) => {
          console.error(error);
          this.$parent.cargando = false;
        });
    },
    removeUploadedDocument(documento) {
      const documentoId = documento.id;
      axios
        .delete("/api/hidrocarburos/eliminar_documento/" + documento.id)
        .then((response) => {
          this.documentos = this.documentos.filter(
            (doc) => doc.id !== documentoId
          );
          this.$swal({
            title: "Documento eliminado",
            text: "El documento ha sido eliminado correctamente.",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$swal({
            title: "Error",
            text: "Hubo un error al eliminar el documento.",
            icon: "error",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    enviarJustificacion() {
      this.contadorEnvios++;

      axios
        .put(
          "/api/hidrocarburos/faltantes/actualizar_estado/" +
            this.diferencia_guia_notificacione_cobro_id
        )
        .then((response) => {
          this.$swal({
            title: "Justificación Enviada",
            text: "La justificación ha sido enviada correctamente.",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.getIndex();
          this.$refs.closeModal.click();
        })
        .catch((error) => {
          console.error(error);
          this.$swal({
            title: "Error",
            text: "Hubo un error al enviar la justificación.",
            icon: "error",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    enviarAreaEncargada() {
      this.$parent.cargando = true;

      const data = {
        diferencia_guia_notificacione_cobro_id: this
          .diferencia_guia_notificacione_cobro_id,
        flete: Number(this.flete),
        calculo: this.calculo,
        nuevoEstado: 4,
        area: this.areaSeleccionada.map((area) => area.numeracion),
        intento: this.datos.cantidad_envios,
      };

      axios
        .put(
          `/api/hidrocarburos/faltantes/area_encargada/${this.diferencia_guia_notificacione_cobro_id}`,
          data
        )
        .then((response) => {
          this.estadoNotificacion = 4;
          this.$swal({
            title: "Justificación Enviada",
            text: "La justificación ha sido enviada correctamente.",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.getIndex();
          this.$parent.cargando = false;
          this.$refs.closeModal.click();
        })
        .catch((error) => {
          console.error(error);
          this.$swal({
            title: "Error",
            text: "Hubo un error al enviar la justificación.",
            icon: "error",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    enviarAdminContrato() {
      const data = {
        diferencia_guia_notificacione_cobro_id: this
          .diferencia_guia_notificacione_cobro_id,
        nuevoEstado: 9,
        funcionario: this.funcionarioSeleccionada,
        intento: this.datos.cantidad_envios,
      };

      axios
        .put(
          `/api/hidrocarburos/faltantes/envio_admin/${this.diferencia_guia_notificacione_cobro_id}`,
          data
        )
        .then((response) => {
          this.estadoNotificacion = 4;
          this.$swal({
            title: "Justificación Enviada",
            text: "La justificación ha sido enviada correctamente.",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.getIndex();
          this.$refs.closeModal.click();
        })
        .catch((error) => {
          console.error(error);
          this.$swal({
            title: "Error",
            text: "Hubo un error al enviar la justificación.",
            icon: "error",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    clearDraftDocuments() {
      this.limpiarFiltros();
      this.ver_productos = false;
      this.viajes = [];
      this.chartOptions = {};
      this.productChartOptions = {};
      this.ver_filtros = false;
      this.cumpli = [];
      this.produc = [];
      this.dest = [];
      this.draftDocuments = [];
      this.flete = null;
      this.calculo = null;

      this.$refs.FaltantesMapa.novedades = [];
      this.$refs.FaltantesMapa.puntosTraza = [];
      this.$refs.FaltantesMapa.markersAd = [];
      this.$refs.FaltantesMapa.form.novedades = false;
      this.$refs.FaltantesMapa.infoContent = "";
      this.$refs.FaltantesMapa.infoWindowPos = {};
      this.$refs.FaltantesMapa.center;
      this.$refs.FaltantesMapa.markersEm = [];
      this.$refs.FaltantesMapa.markersIns = [];
      this.$refs.FaltantesMapa.markersGui = [];
      this.$refs.FaltantesMapa.markersGuides = [];
      this.$refs.FaltantesMapa.det_mapa = [];
      this.$refs.FaltantesMapa.det_ruta = [];
      this.$refs.FaltantesMapa.markers = [];
      this.CampoTextoMedicion = "";
      this.CampoTextoSeguridad = "";
      this.CampoTextoTransporte = "";
      this.CampoTextoConfirmador = "";
      this.estadoMedicion = null;
      this.estadoSeguridad = null;
      this.estadoTransporte = null;
      this.estadoConfirmador = null;

      this.isCollapsed6 = true;
      this.$parent.getIndex();
      this.$refs.closeModal.click();
    },

    handleSitioCargueChange(value) {
      this.filtros.sitioCargue = parseInt(value, 10); // Convierte a entero
      this.consultarPlaca(); // Llama a tu método de consulta
    },

    async consultarRound() {
      if (this.round) {
        return;
      }
      this.$parent.cargando = true;

      await axios
        .get(`/api/hidrocarburos/faltantes/consultarRound/${this.datos.id}`)
        .then((response) => {
          this.$parent.cargando = false;

          this.round = response.data;
        })
        .catch((error) => {
          console.error("Error en la consulta:", error);
        });
    },
    async consultarLoteo() {
      if (this.loteoSi) {
        return;
      }
      this.$parent.cargando = true;

      await axios
        .get(`/api/hidrocarburos/faltantes/consultarLoteo/${this.datos.id}`)
        .then((response) => {
          this.$parent.cargando = false;

          this.loteoSi = response.data.guiaAnterior;
          this.loteoAn = response.data.guiaSiguiente;
        })
        .catch((error) => {
          console.error("Error en la consulta:", error);
        });
    },
    async consultarPlaca(page = 1) {
      this.$parent.cargando = true;
      this.placa = this.datos.viaje.vehiculo_id;
      if (this.filtros.conductor == "") {
        this.filtros.conductor == null;
      }
      await axios
        .get(
          `/api/hidrocarburos/faltantes/consultarPlaca/${this.placa}?page=${page}`,
          {
            params: this.filtros,
          }
        )
        .then((response) => {
          this.$parent.cargando = false;

          this.produc = response.data.viajesCond;
          this.dest = response.data.viajesDes;
          this.conductores = response.data.conductores;
          this.cumpli = response.data.viajesCumpli;

          for (
            let index = 0;
            index < response.data.viajes.data.length;
            index++
          ) {
            const viaje = response.data.viajes.data[index];

            response.data.viajes.data[index].diferencia =
              parseFloat(viaje.cierre_guias[0].barriles_nsv) -
              parseFloat(viaje.det_guias[0].nsv);
          }

          this.viajes = response.data.viajes;
          this.initializeChart();
        })
        .catch((error) => {
          console.error("Error en la consulta:", error);
        });
    },
    limpiarFiltros() {
      if (this.isCollapsedFilter === true) {
        (this.filtros.mes = null),
          (this.filtros.sitioDescargue = null),
          (this.filtros.sitioCargue = null),
          (this.filtros.dia = null),
          (this.filtros.producto = null),
          (this.filtros.conductor = null),
          (this.filtros.status = null),
          (this.filtros.anio = null);
        this.consultarPlaca();
      }
    },
    initializeChart() {
      this.chartOptions = {
        chart: {
          type: "column",
        },
        title: {
          text: "Cumplimiento por Destino",
        },
        xAxis: {
          categories: Object.keys(this.dest),
          title: {
            text: "Sitio Descargue",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Conteo",
          },
        },
        series: [
          {
            name: "Cumple",
            data: this.getData("Cumple"),
          },
          {
            name: "Faltante",
            data: this.getData("Faltante"),
          },
          {
            name: "Sobrante",
            data: this.getData("Sobrante"),
          },
        ],
        tooltip: {
          shared: true,
          valueSuffix: " unidades",
        },
      };

      this.productChartOptions = {
        chart: {
          type: "column",
        },
        title: {
          text: "Cumplimiento por Producto",
        },
        xAxis: {
          categories: Object.keys(this.produc),
          title: {
            text: "Producto",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Conteo",
          },
        },
        series: [
          {
            name: "Cumple",
            data: this.getProductData("Cumple"),
          },
          {
            name: "Faltante",
            data: this.getProductData("Faltante"),
          },
          {
            name: "Sobrante",
            data: this.getProductData("Sobrante"),
          },
        ],
        tooltip: {
          shared: true,
          valueSuffix: " unidades",
        },
      };
    },
    getData(status) {
      return Object.keys(this.dest).map(
        (year) => this.dest[year][status]?.count || 0
      );
    },
    getProductData(status) {
      return Object.keys(this.produc).map(
        (product) => this.produc[product][status]?.count || 0
      );
    },

    enviarRespuestaSeguridad() {
      if (
        this.datos.diferencias_guia_areas &&
        this.datos.diferencias_guia_areas.length > 0
      ) {
        const areasFiltradas = this.datos.diferencias_guia_areas.filter(
          (areaObj) => areaObj.area === "1"
        );

        const areaObj = areasFiltradas.slice(-1)[0];

        if (areaObj) {
          let data = {
            estadoSeguridad: this.estadoSeguridad,
            id: areaObj.id,
            diferencia_guia_notificacione_cobro_id:
              areaObj.diferencia_guia_notificacione_cobro_id,
            user_id: this.$store.getters.getUser.id,
            textoSeguridad: this.CampoTextoSeguridad,
            area: areaObj.area,
          };

          axios({
            method: "POST",
            url: "/api/hidrocarburos/faltantes/actualizarAreaSeguridad",
            data: data,
          })
            .then((response) => {
              this.$swal({
                title: "Se ha enviado la respuesta",
                text: "La justificación ha sido enviada correctamente.",
                icon: "success",
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.getIndex();
              this.clearDraftDocuments();
              this.$refs.closeModal.click();
            })
            .catch((error) => {
              console.error(error);
              this.$swal({
                title: "Error",
                text: "Hubo un error al enviar la respuesta.",
                icon: "error",
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      }
    },

    enviarRespuestaMedicion() {
      if (
        this.datos.diferencias_guia_areas &&
        this.datos.diferencias_guia_areas.length > 0
      ) {
        const areasFiltradas = this.datos.diferencias_guia_areas.filter(
          (areaObj) => areaObj.area === "3"
        );

        const areaObj = areasFiltradas.slice(-1)[0];

        if (areaObj) {
          let data = {
            estadoMedicion: this.estadoMedicion,
            id: areaObj.id,
            diferencia_guia_notificacione_cobro_id:
              areaObj.diferencia_guia_notificacione_cobro_id,
            user_id: this.$store.getters.getUser.id,
            textoMedicion: this.CampoTextoMedicion,
            novedadMedicion: this.novedadMedicion,
            area: areaObj.area,
            intento: this.datos.cantidad_envios,
          };

          axios({
            method: "POST",
            url: "/api/hidrocarburos/faltantes/actualizarAreaMedicion",
            data: data,
          })
            .then((response) => {
              this.$swal({
                title: "Se ha enviado la respuesta",
                text: "La justificación ha sido enviada correctamente.",
                icon: "success",
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.getIndex();
              this.clearDraftDocuments();
              this.$refs.closeModal.click();
            })
            .catch((error) => {
              console.error(error);
              this.$swal({
                title: "Error",
                text: "Hubo un error al enviar la respuesta.",
                icon: "error",
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      }
    },

    enviarRespuestaTransporte() {
      if (
        this.datos.diferencias_guia_areas &&
        this.datos.diferencias_guia_areas.length > 0
      ) {
        // Filtrar las áreas que sean igual a "2"
        const areasFiltradas = this.datos.diferencias_guia_areas.filter(
          (areaObj) => areaObj.area === "2"
        );

        // Obtener el último elemento filtrado
        const areaObj = areasFiltradas.slice(-1)[0];

        if (areaObj) {
          // Asegúrate de que exista un objeto con area "2"
          let data = {
            estadoTransporte: this.estadoTransporte,
            id: areaObj.id,
            diferencia_guia_notificacione_cobro_id:
              areaObj.diferencia_guia_notificacione_cobro_id,
            user_id: this.$store.getters.getUser.id,
            textoTransporte: this.CampoTextoTransporte,
            area: areaObj.area,
            intento: this.datos.cantidad_envios,
          };
          axios({
            method: "POST",
            url: "/api/hidrocarburos/faltantes/actualizarAreaTransporte",
            data: data,
          })
            .then((response) => {
              this.$swal({
                title: "Se ha enviado la respuesta",
                text: "La justificación ha sido enviada correctamente.",
                icon: "success",
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.getIndex();
              this.clearDraftDocuments();
              this.$refs.closeModal.click();
            })
            .catch((error) => {
              console.error(error);
              this.$swal({
                title: "Error",
                text: "Hubo un error al enviar la respuesta.",
                icon: "error",
                timer: 3000,
                timerProgressBar: true,
              });
            });
        } else {
          console.error("No se encontró un área con valor '2'.");
        }
      }
    },

    enviarRespuestaConfirmador() {
      if (this.datos.estado == 9) {
        try {
          const response = axios
            .post("/api/hidrocarburos/faltantes/envioCorreoDiferencias", {
              estadoConfirmador: this.estadoConfirmador,
              CampoTextoConfirmador: this.CampoTextoConfirmador,
              id: this.datos.id,
            })
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Respuesta enviada",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.getIndex();
              this.clearDraftDocuments();
              this.$refs.closeModal.click();
            });
        } catch (error) {
          console.error("Error al enviar la respuesta:", error);
          alert("Hubo un error al enviar la respuesta");
        }
      } else {
        try {
          const response = axios
            .post("/api/hidrocarburos/faltantes/confirmador", {
              estadoConfirmador: this.estadoConfirmador,
              CampoTextoConfirmador: this.CampoTextoConfirmador,
              id: this.datos.id,
            })
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Respuesta enviada",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.getIndex();
              this.clearDraftDocuments();
              this.$refs.closeModal.click();
            });
        } catch (error) {
          console.error("Error al enviar la respuesta:", error);
          alert("Hubo un error al enviar la respuesta");
        }
      }
    },
    enviarCorreo() {
      axios
        .get("/api/hidrocarburos/postulaciones/enviarCorreo", {
          params: {
            solicitud_diaria_id: this.$route.params.solicitud_diaria_id,
            empresa_id: this.$route.params.empresa_id,
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Correo enviado satisfactoriamente",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },

  mounted() {
    this.getAreas();
    this.getEstados();
    this.getSitios();
  },
};
</script>
<style>
.well-legend-rounded {
  border-radius: 9px;
  padding: 3px;
  margin: 9px;
  max-width: 290px;
}
.btn-toggle {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: white;
  margin-left: 10px;
}
.small-text {
  font-size: 1rem;
}
.small-button {
  font-size: 1em;
}
.form-control-file {
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.text-cumple {
  font-weight: bold;
  color: green;
}

.text-faltante {
  font-weight: bold;
  color: red;
}

.text-sobrante {
  font-weight: bold;
  color: rgb(75, 147, 171);
}

.bg-cumple {
  background-color: rgb(171, 219, 171);
}

.bg-faltante {
  background-color: rgb(226, 145, 145);
}

.bg-sobrante {
  background-color: rgb(131, 197, 222);
}
.bg-porcentaje {
  background-color: rgb(201, 207, 89);
}
.text-porcentaje {
  font-weight: bold;
  color: rgb(128, 128, 128);
}
.textWhite {
  color: white;
}
.highcharts {
  height: 400px; /* Ajusta la altura según tus necesidades */
}
</style>
